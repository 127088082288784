body {
}

* {
    box-sizing: border-box;
}

.label {
    font-weight: 900;
}

input[type=text], input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
}

    input[type=submit]:hover {
        /*background-color: black;*/
        /*color: white;*/
        border: 1px solid black;
    }

.container {
    border-radius: 5px;
    background-color: #eee4;
    padding: 20px;
    width:300px;
    display:inline-block;
    text-align:left;
   
}

.validation_error {
    color:red !important;
    margin-top: -16px;
    margin-bottom: 16px;
    font-weight:900;
}

.AlertTitle {
    font-size: 1.5rem !important;
}

.Alert {
    font-size: 1.3rem !important;
    
}
:root {
    --my-color: white;
    

}

.nodecor {
    text-decoration: none !important;
     
}


#light {
    
    background-color: white;
    color: black;
}


#dark {
    
    background-color: black;
    color: white;
  
}

html {
    
}

body {
    font-family: 'KoHo', sans-serif !important;
}



.App {
  text-align: center;

}

a
{
    color:inherit;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.alice-carousel__prev-btn {
    text-align: inherit !important;
}



.my_separator {
    padding-top: 4rem;
}

.home_gallery_img {
    width: 100% !important;
    /*height: 84vh;*/
    aspect-ratio: 4/3;
    /*border-radius: 10px;*/
}

.featured_img {
    max-width: 100% !important;
    height: 400px;
    border-radius: 10px;
}

.gallery_img {
    max-width: 100% !important;
    /*height: 400px;*/
    border-radius: 5px;
    /*aspect-ratio:auto;*/
}



.gallery_img_small {
    max-width: 100% !important;
    height: 200px;
}

h1 {
    padding-top: 2rem;
    
}



h4 {
    font-size: 2rem;
}

p {
    display: flex;
    flex-wrap:wrap;
    text-align:left;
    justify-content: left;
}

.always_center {
    justify-content:center;
}


.swiper {
    overflow: hidden !important;
    
}



.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute !important;
    top:0px;
}

.swiper-button-prev,
.swiper-button-next {
    top:100px !important;
    
}

.swiper-button-prev:after,
.swiper-button-next:after {
    color: var(--my-color);

    font-size:6rem !important;
    font-weight: 900;
    /*color: white*/
    z-index: 999;
    /*position:absolute;*/
    
}




.overlay_opis_hidden {
    display: none;
}

.overlay_text_naziv {
    position: absolute;
    display: flex;
    max-width: 75%;
    flex-wrap: wrap;
    text-align: left;
    z-index: 999;
    bottom: 15%;
    left: 75px;
    font-size: 4rem;
    color: white;
}

    .overlay_text_naziv:after {
        content: attr(data-text);
        -webkit-text-stroke: 6px gray;
        color: gray;
        position: absolute;
        left: 0;
        z-index: -1
    }

.overlay_text_lokacija1 {
    position: absolute;
    display: flex;
    /*max-width: 75%;*/
    flex-wrap: wrap;
    text-align: left;
    z-index: 999;
    bottom: 5%;
    left: 75px;
    font-size: 2.5rem;
    color:white;

}

    .overlay_text_lokacija1:after {
        content: attr(data-text);
        -webkit-text-stroke: 6px gray;
        color: gray;
        position: absolute;
        left: 0;
        z-index: -1
    }



.overlay_opis:hover ~ .image:after
{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.6);
    opacity: 0.5;
    transition: all 1s;
    -webkit-transition: all 1s;
}


.overlay_text_lokacija2 {
    position: absolute;
    display: flex;
    max-width: 75%;
    flex-wrap: wrap;
    text-align: left;
    z-index: 999;
    top: 55%;
    left: 50px;
    font-size: 1.5rem;
}

@media (max-width: 500px) {

    .swiper-button-prev,
    .swiper-button-next {
        top: 50px !important;
    }

    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-size: 4rem !important;
    }


    .my_separator {
        padding-top: 2rem;
    }
    .featured_img {
        
    }

    .gallery_img {
        /*height: 200px;*/
    }

    /*.home_gallery_img {
        height: 60vh;
    }*/

    h1 {
        font-size: 2rem;
    }

    .overlay_opis {

        
    }

    

    .overlay_opis ~ .image:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.6);
        opacity: 0.2;
        transition: all 1s;
        -webkit-transition: all 1s;
    }

    .overlay_text_naziv, 
    .overlay_text_lokacija1 {
        left: 50px;
        /*max-width:100%;*/
    }

    .overlay_text_naziv {
        font-size: 2.5rem;
        
    }

    .overlay_text_lokacija1 {
        font-size: 2rem;
        bottom: 5%
    }
}

@media (max-width: 380px) {
    .overlay_text_naziv {
        font-size: 1.7rem;
    }

    .overlay_text_lokacija1 {
        font-size: 1.3rem;
        bottom: 5%
    }

}

@media (max-width: 275px) {
    .overlay_text_naziv {
        font-size: 1.3rem;
        bottom: 25%;
    }

    .overlay_text_lokacija1 {
        font-size: 1.1rem;
        bottom: 5%
    }
}


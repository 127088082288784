body {
}

/********************/
/*   Even columns   */
/********************/

.my_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 3rem;
    
 
}

@media (max-width: 980px) {
    .my_div {
        justify-content:center;
    }
    div > h4, p {
        text-align:center;
        justify-content:center;
    }
}

@media (max-width: 400px) {
    .my_div {
        margin: 1rem;
    }
    
}

@media (max-width: 510px) {
    .box {
        width: 300px !important;
    }
}

.my_div > * {
    flex: 1 1 300px;
}

/********************/
/*    Typography    */
/********************/

h4 {
    padding-bottom: 2.5rem;
}

h4,
strong {
    display: flex;
    justify-content: left;
}

/********************/
/* Bubble animation */
/********************/

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.box {
    
    background-size:cover;
    background-position: center, center;
    height: 300px;
    width: 500px;
    animation: animate 3s ease-in-out infinite;
    transition: all 1s ease-in-out;
}

@keyframes animate {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }
    25% {
        border-radius: 50% 50% 30% 70% / 80% 30% 70% 40%
    }
    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%
    }
    75% {
        border-radius: 50% 50% 30% 70% / 60% 30% 80% 40%
    }
    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%
    }
}


.nodecor {
    text-decoration:none !important;
}


.my_hr {
    border-top: 1px dotted lightgrey;
}

.my_container_g {
    margin: 2rem;
}

/********************/
/*   Even columns   */
/********************/

.my_div_g {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 2rem;
}

@media (max-width: 980px) {
    .my_div_g {
        justify-content: left !important;
    }

    .my_div_g > p {
        text-align: left !important;
        justify-content: left !important;
    }


}

@media (max-width: 400px) {
    .my_div_g {
        margin: 1rem;
    }

}

@media (max-width: 510px) {
    .wrapper_g {
        width: 510px !important;
        justify-content:center !important;
        flex: 1 1 !important;
        margin-bottom: 1rem !important;
        
    }
}

.my_div_g > * {
    flex: 1 1 300px;
}

.my_div_g p i {
    font-size:1rem;
}

/*.my_objekt p i {
    font-size:1rem;
}*/


.wrapper_g {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 50px;
    flex: 0 1;
    margin-right: 1rem;
}



.box_g { 
   
  }











/*.even_columns {
    display:flex;
    flex-wrap: wrap;
    justify-content:left;

}

.even_columns_slika {
    align-self:center;
    margin-right:1rem;
}

.even_columns_slika > * {
    flex: 0 1;
}*/



.op_featured_img {
    /*min-width: 85vw !important;*/
    max-width: 100% !important;
    aspect-ratio: auto;
    /*height: 84dvh;*/
    
}

@media (max-width: 450px) {
    .op_featured_img {
        /*height: 30dvh;*/
    }
}
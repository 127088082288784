






.p2_nodecor {
    text-decoration: none !important;
}


.p2_my_hr {
    border-top: 1px dotted lightgrey;
    margin-top: 10px;
}

.p2_image {
    max-width: 500px;
    min-width: 500px;
    

}

/********************/
/*   Even columns   */
/********************/



.p2_my_div_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    
    margin-left: 1rem;

}





.p2_my_div {
    display: flex;
    flex-wrap: wrap;
    align-items:flex-start;
    justify-content: left;
    margin-left: 1rem;
    margin-top: 1rem;
   
}

@media (max-width: 980px) {
    .p2_my_div {
        justify-content: center;
    }

    div > h4, p {
        text-align: center;
        justify-content: center;
    }
}

@media (max-width: 400px) {
    .p2_my_div {
        margin: 1rem;
    }
}



.p2_my_div > * {
    flex: 0 0 300px;
    flex-wrap: wrap;
}

.p2_my_div_1 > * {
    flex: 0 0 100%;
}




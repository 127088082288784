

/********************/
/*   Even columns   */
/********************/

.my_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    margin: 3rem;
}

@media (max-width: 980px) {
    .my_div {
        justify-content: center;
    }

    div > h4, p {
        text-align: center;
        justify-content: center;
    }
}

@media (max-width: 400px) {
    .my_div {
        margin: 1rem;
    }
}

@media (max-width: 510px) {
    .box {
        width: 300px !important;
    }
}

.my_div > * {
    flex: 1 1 300px;
}